const btns = document.querySelectorAll('button');
const links = document.querySelectorAll('a[href]');

const removeFocus = () => {

  if (!links) {
    return;
  }

  links.forEach((link) => {
    link.addEventListener('click', () => {
      link.blur();
    });
  });

  btns.forEach((btn) => {

    if (!btns) {
      return;
    }

    btn.addEventListener('click', () => {
      btn.blur();
    });
  });
};

export {removeFocus};
