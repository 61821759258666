// import Swiper from './../vendor/swiper';

let swiper1;
const initIntroSlider = () => {

  swiper1 = new Swiper('.intro__slider', {
    loop: true,
    autoplay: {
      delay: 3000,
    },
    // Navigation arrows
    // navigation: {
    //   nextEl: '.swiper-button-next',
    //   prevEl: '.swiper-button-prev',
    // },
  });
};

export {initIntroSlider, swiper1};
