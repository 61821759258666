const toggle = document.querySelector('.j-header-toggle');
const mainNavList = document.querySelector('.main-nav__list');
const breakpoint = window.matchMedia('(max-width:1023px)');
const header = document.querySelector('.header');
const links = document.querySelectorAll('.main-nav__link');

const isMainNavActive = () => {
  return header.classList.contains('is-active');
};

const breakpointChecker = () => {
  if (breakpoint.matches) {
    toggle.addEventListener('click', () => {
      if (isMainNavActive()) {
        header.classList.remove('is-active');
        window.enableBodyScroll(mainNavList);
      } else {
        header.classList.add('is-active');
        window.disableBodyScroll(mainNavList);

        links.forEach((link) => {
          link.addEventListener('click', () => {
            header.classList.remove('is-active');
            window.enableBodyScroll(mainNavList);
          });
        });
      }
    });
  }
};

const initMainNav = () => {
  if (!toggle) {
    return;
  }

  breakpoint.addListener(breakpointChecker);
  breakpointChecker();
};

export {initMainNav};
