import {Tabs} from './tabs';

let tabs;

const initTabs = () => {
  tabs = new Tabs();
  // Используйте в разработке экспортируемую переменную tabs, window сделан для бэкэнда
  window.tabs = tabs;


  // Номер активного таба в родителе
  const calcTabsCheckboxes = document.querySelectorAll('[data-tabs="control"]');

  for (let i = 0; i < calcTabsCheckboxes.length; i++) {
    calcTabsCheckboxes[i].addEventListener('click', (e) => {
      e.currentTarget.checked = true;
      changeParentIdTab(e.currentTarget);
    });
  }

  function changeParentIdTab(el) {
    const index = el.dataset.index || 0;
    const parent = el.closest('[data-active-tab]');

    if (parent) {
      parent.dataset.activeTab = index;
    }
  }
};

export {initTabs, tabs};
