const slider = document.querySelector('.cost__input--1');
const slider2 = document.querySelector('.cost__input--2');
const slider3 = document.querySelector('.cost__input--3');
const optionsStretch = {
  range: {
    'min': [1],
    'max': [12],
  },
  tooltips: true,
  start: [5],
  step: 0.05,
  connect: [true, false],
};
const optionsDefault = {
  range: {
    'min': [0.5],
    'max': [3],
  },
  tooltips: true,
  start: [1.5],
  step: 0.05,
  connect: [true, false],
};
let uiSlidersObj = [];


const initUiSliders = () => {
  createSlider(slider, optionsStretch);
  createSlider(slider2, optionsDefault);
  createSlider(slider3, optionsDefault);
  window.uiSliders = uiSlidersObj;
};


const createSlider = (el, options) => {
  if (!slider || !noUiSlider) {
    return;
  }

  const input = getBoundInput(el);
  const uiSlider = noUiSlider.create(el, options);

  uiSlider.on('update', (val) => {

    if (input) {
      input.value = val[0];
    }
  });

  uiSlidersObj.push(uiSlider);
};


function getBoundInput(el) {
  const form = el.closest('form');

  if (!form) {
    return false;
  }

  return form.querySelector('input[name="length"]');
}


export {initUiSliders, uiSlidersObj};

