const items = document.querySelectorAll('.catalog__controls .custom-select__item');
const breakpoint = window.matchMedia('(max-width:767px)');
const elements = document.querySelectorAll('.catalog__element');
const content = document.querySelector('.catalog__content');

const breakpointChecker = () => {
  if (breakpoint.matches) {
    items.forEach((item) => {
      item.addEventListener('click', () => {
        let i = Number(item.dataset.selectValue.slice(3));

        elements.forEach((el) => {
          el.classList.remove('is-active');
        });

        elements[i - 1].classList.add('is-active');
      });
    });
  } else {
    setTimeout(() => {
      content.style.height = 'unset';
    }, 300);
  }
};

const initCatalogSelect = () => {
  if (!items) {
    return;
  }

  breakpoint.addListener(breakpointChecker);
  breakpointChecker();
};

export {initCatalogSelect};
