const portfolioSlider = '.js-slider-portfolio';

const initSliderPortfolio = () => {
  if (!document.querySelector(portfolioSlider)) {
    return false;
  }

  const swiper = new Swiper(portfolioSlider, {
    autoHeight: true,
    slidesPerView: 3,
    loop: true,
    grabCursor: true,
    breakpoints: {
      320: {
        slidesPerView: 'auto',
        freeMode: true,
      },
      1023: {
        slidesPerView: 3,
      },
    },
    navigation: {
      nextEl: '.portfolio__controls-next',
      prevEl: '.portfolio__controls-prev',
    },
  });

  return swiper;
};

export {initSliderPortfolio};
