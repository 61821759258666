import {Modals} from './modals';
import {serializeArray} from '../../utils/functions';

let modals;

// Здесь реализован пример открытия модалки через колбэк закрытия
// const openModalInCloseCallback = (name, context = this) => {
//   context._enableScrolling = false;
//   context._setSettings('default');
//   modals.open(name);
// };

// closeCallback() {
//   openModalInCloseCallback('modal-5');
// },


const settings = {
  'default': {
    preventDefault: true,
    stopPlay: true,
    lockFocus: true,
    startFocus: true,
    focusBack: true,
    eventTimeout: 400,
    openCallback: (name, e) => {
      switch (name) {
        case 'buy': buyIt(name, e);
          break;
        case 'calc': calcIt(name, e);
          break;
      }
    },
    closeCallback: false,
  },
};


const calcIt = (name, event) => {
  const $btn = event.target;
  const $form = $btn.closest('form');
  const $modalForm = document.querySelector(`[data-modal="${name}"] form`);

  if ($modalForm) {
    const hiddenInputs = $modalForm.querySelectorAll('.data-input');
    const dataForm = serializeArray($form);

    for (let i = 0; i < hiddenInputs.length; i++) {
      const el = hiddenInputs[i];
      el.parentNode.removeChild(el);
    }

    for (let i = 0; i < dataForm.length; i++) {
      const item = dataForm[i];
      const newInput = document.createElement('input');

      newInput.classList.add('data-input');

      if (item.value) {
        newInput.type = 'hidden';
        newInput.name = item.name;
        newInput.value = item.value;
        $modalForm.appendChild(newInput);
      }
    }
  }
};

const buyIt = (name, event) => {
  const $btn = event.target;
  const $card = $btn.closest('.catalog-card');
  const dataPrice = $btn.dataset.price;
  const dataName = $btn.dataset.name;
  const $modal = document.querySelector(`[data-modal="${name}"]`);
  const $inputName = document.getElementById('goods-name');
  const $inputPrice = document.getElementById('goods-price');
  const $modalImgWrapper = document.getElementById('goods-img');

  if (!$modal) {
    return;
  }

  if ($card && $modalImgWrapper) {
    const $cardImg = $card.querySelector('picture');

    $modalImgWrapper.innerHTML = '';

    if (!$cardImg) {
      return;
    }

    const $imageClone = $cardImg.cloneNode(true);
    $modalImgWrapper.appendChild($imageClone);
  }

  if (dataPrice && $inputPrice) {
    $inputPrice.value = dataPrice;
  }

  if (dataName && $inputName) {
    $inputName.value = dataName;
  }
};

const initModals = () => {
  const modalElements = document.querySelectorAll('.modal');
  if (modalElements.length) {
    modalElements.forEach((el) => {
      setTimeout(() => {
        el.classList.remove('modal--preload');
      }, 100);
    });
  }

  modals = new Modals(settings);
  // Используйте в разработке экспортируемую переменную modals, window сделан для бэкэнда
  window.modals = modals;
};

export {modals, initModals};
